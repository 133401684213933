<template>
  <div class="kt-widget4">
    <b-overlay rounded="sm" :show="busy">
    <perfect-scrollbar style="max-height: 100vh; position: relative;">
      <template v-for="(item, i) in items">
        <div class="kt-widget4__item" :key="`item-${i}`">
          <div class="kt-widget4__pic kt-widget4__pic--pic">
            <b-img-lazy :src="item.avatar || '/assets/media/users/default.jpg'" blank-src="/assets/media/misc/loading.svg" />
          </div>
          <div class="kt-widget4__info">
            <span class="kt-widget4__username">{{ item.nickname || $t('COMMON.NO_BODY') }}</span>
            <p class="kt-widget4__text">{{ item.time_ago }}</p>
          </div>
          <span v-if="false" class="kt-badge kt-badge--inline" :class="{'kt-badge--info': ! item.is_vip, 'kt-badge--warning': item.is_vip}">{{ item.is_vip ? 'VIP' : 'ئادەتتىكى ئەزا' }}</span>
        </div>
      </template>
    </perfect-scrollbar>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "widget-4",
  components: {},
  props: {
    items: Array,
    busy: Boolean
  }
};
</script>
