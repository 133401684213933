<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-8 order-lg-3 order-xl-1">
        <!--begin:: Widgets/Inbound Bandwidth-->
        <KTPortlet
                :title="$t('PAGES.DASHBOARD.STATISTICS.title')"
                class="kt-portlet--fit kt-portlet--head-noborder"
                head-class="kt-portlet__space-x"
                :fluidHalfHeight="false"
                :head-no-border="true"
                :body-fluid="true"
        >
          <template v-slot:toolbar>
            <button @click="onClickRefreshUserStatics" v-b-tooltip.hover :title="$t('COMMON.REFRESH')" class="btn btn-clean btn-sm btn-icon btn-icon-md">
              <i class="flaticon2-refresh-arrow"></i>
            </button>
          </template>
          <template v-slot:body>
            <Widget20_1
              @count="widgetCount"
              :title="widgetTitle"
              :update="userStaticUpdate"
              :desc="$t('PAGES.DASHBOARD.STATISTICS.desc')"
            ></Widget20_1>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Inbound Bandwidth-->
        <div class="kt-space-20"></div>
        <!--begin:: Widgets/Outbound Bandwidth-->
        <KTPortlet
                v-bind="{
          title: $t('PAGES.DASHBOARD.PROTOCOL.title'),
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x',
          headNoBorder: true,
          bodyFluid: true
          }"
        >
          <template v-slot:body>
            <div class="kt-portlet__space-x">
              <protocol-content />
            </div>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Outbound Bandwidth-->
      </div>
      <div class="col-xl-4 col-lg-6 order-lg-3 order-xl-1">
        <KTPortlet
          :title="$t('PAGES.DASHBOARD.USERS.title')"
        >
          <template v-slot:toolbar>
            <button @click="refreshNewlyUser" v-b-tooltip.hover :title="$t('COMMON.REFRESH')" class="btn btn-clean btn-sm btn-icon btn-icon-md">
              <i class="flaticon2-refresh-arrow"></i>
            </button>
          </template>
          <template v-slot:body>
            <UserList v-bind:items="latestUsers" :busy="userBusy"></UserList>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ProtocolContent from "@v@/components/protocol/ProtocolContent";
import UserList from "../partials/widgets/UserList";
import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
import userMixin from "@m@/user.mixin";
import formBusyMixin from "@m@/common/formBusy.mixin";


/**
 * Sample widgets data source
 */
import widget4 from "@/common/mock/widget-4.json";

export default {
  name: "dashboard",
  mixins: [ formBusyMixin, userMixin ],
  components: {
    KTPortlet,
    UserList,
    Widget20_1,
    ProtocolContent
  },
  data() {
    return {
      widget4: widget4,
      userBusy: false,
      orderBusy: false,
      widgetTitle: '',
      widgetOrderTitle: '',
      userStaticUpdate: false,
    };
  },
  created() {
    this.getLatestUsers();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGES.DASHBOARD.BREAD_CRUMBS_TITLES.first') }]);
  },
  methods: {
    widgetCount(e){
      this.widgetTitle = String(e);
      this.userStaticUpdate = false;
    },
    refreshNewlyUser(event) {
      this.getLatestUsers();
    },
    onClickRefreshUserStatics(){
      this.userStaticUpdate = true;
    }
  }
};
</script>
