<template>
  <div class="kt-widget20">
    <b-overlay :show="chartBusy">
    <div class="kt-widget20__content kt-portlet__space-x">
      <span class="kt-widget20__number kt-font-brand">{{ title }}</span>
      <span class="kt-widget20__desc">{{ desc }}</span>
    </div>
    <div class="kt-widget20__chart" style="height:130px;">
      <Chart1 ref="chart" v-bind:options="chartOptions"></Chart1>
    </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";
import Chart1 from "@/views/partials/widgets/Chart1.vue";
import userMixin from "@m@/user.mixin";
import formBusyMixin from "@m@/common/formBusy.mixin";
import {debugConsole} from "@c@/helpers/helpers";

export default {
  name: "widget-20",
  components: {
    Chart1
  },
  mixins: [formBusyMixin, userMixin],
  data() {
    return {
      chartOptions: {},
      chartBusy: false
    };
  },
  props: {
    title: String,
    desc: String,
    update: Boolean
  },
  created() {
    this.getUserWeekStatisticsSync();
  },
  watch: {
    'update': function (v) {
      if ( v === true )
        this.getUserWeekStatisticsSync();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
    formatChartData(statisticsData){
      let labels = [];
      let data = [];
      let count = 0;
      for ( let item of statisticsData ){
        labels.push(item.date);
        data.push(item.value);
        count += item.value;
      }
      const ctx = this.$refs["chart"].$el.getContext("2d");
      var gradient = ctx.createLinearGradient(0, 0, 0, 240);
      gradient.addColorStop(
        0,
        Chart.helpers
          .color("#d1f1ec")
          .alpha(1)
          .rgbString()
      );
      gradient.addColorStop(
        1,
        Chart.helpers
          .color("#d1f1ec")
          .alpha(0.3)
          .rgbString()
      );
      const defaults = {
        data: {
          labels,
          datasets: [
            {
              label: this.$t('PAGES.DASHBOARD.STATISTICS.label'),
              backgroundColor: gradient,
              borderColor: this.layoutConfig("colors.state.success"),
              pointBackgroundColor: Chart.helpers
                      .color("#000000")
                      .alpha(0)
                      .rgbString(),
              pointBorderColor: Chart.helpers
                      .color("#000000")
                      .alpha(0)
                      .rgbString(),
              pointHoverBackgroundColor: this.layoutConfig("colors.state.success"),
              pointHoverBorderColor: Chart.helpers
                      .color("#000000")
                      .alpha(0.1)
                      .rgbString(),
              data
            }
          ]
        }
      };
      this.chartOptions = Object.assign({}, defaults, this.chartOptions);
      this.$emit('count', count)
    },
    getUserWeekStatisticsSync(){
      this.getUserWeekStatistics({
        begin: ()=>{
          this.showBusy && this.showBusy('chartBusy');
          debugConsole({message: 'loading'});
        },
        after: (response, state)=>{
          let { data } = response;
          this.formatChartData(data);
          this.hideBusy && this.hideBusy('chartBusy');
          debugConsole({message: 'loaded'});
        },
      })
    },
  }
};
</script>
